import React from 'react'
import { Link } from 'gatsby'

import App from 'src/components/App/App'
import Head from 'src/components/Head/Head'
import Header from 'src/components/Header/Header'
import styles from './privacy.module.scss'

const Privacy = () => {
  return (
    <App>
      <Head pageTitle="Privacy Policy" />
      <Header pageTitle="Privacy Policy" />
      <main className={styles.root}>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy describes how PicCollage. (
          <strong>"PicCollage"</strong>, <strong>"We"</strong>,{' '}
          <strong>"Us"</strong>, or <strong>"Our"</strong>) protects your
          privacy when you use PicCollage (the{' '}
          <strong>"PicCollage Service"</strong>) which includes the website
          located at www.piccollage.com (the <strong>"Site"</strong>) and all
          mobile phone applications developed by{' '}
          <Link to="/">Cardinal Blue Software, Inc.</Link>(
          <strong>"Mobile"</strong>), with the exception of the “PicCollage EDU”
          application, which has a separate privacy policy.
        </p>

        <p>
          PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE
          SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED
          HEREIN AND ALL TERMS AND CONDITIONS INCORPORATED BY REFERENCE. IF YOU
          DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH BELOW, YOU
          MAY NOT USE THE PICCOLLAGE SERVICE.
        </p>

        <p>
          This Privacy Policy applies to information (including personal
          information) collected through the PicCollage Service. For the purpose
          of this Privacy Policy, "personal information" means information that
          identifies you personally, either alone or in combination with other
          information available to us. Personal information does not include
          technical, device, or usage information that cannot be used to
          identify you personally, nor does it include "aggregate" information,
          which is data we collect about the use of the PicCollage Service or
          about a group or category of services or users, from which individual
          identities or other personal information have been removed. This
          Privacy Policy in no way restricts or limits our collection and use of
          aggregate and non-personal information, and we may share such data
          about our users with third parties for various purposes. This Privacy
          Policy is part of and incorporated into PicCollage's{' '}
          <Link to="/tos">Terms of Service</Link> (
          <strong>"Terms of Service"</strong>).
        </p>

        <h2>The What & How of Information We Collect</h2>
        <p>
          We collect certain personal information that you voluntarily provide
          to us. For example, in the case where you create a PicCollage log-in
          account, we may collect your name, email address, username, social
          network information and other information that you choose to provide
          to us. The PicCollage app may be used without logging in, in which
          case we do not collect the user’s personal information. We may also
          obtain information from other companies and combine that with
          information we collect on the PicCollage Service; for example, when
          you link your account with Facebook, we will have access to your name.
        </p>
        <p>
          When you use the PicCollage Service, we may automatically collect some
          information. For example, it is standard for your Web browser to
          automatically send information to every Web site you visit, including
          ours. Such information includes your computer's Internet Protocol
          ("IP") address, access times, browser type and language, and referring
          Web site addresses. We also collect information about your computer's
          operating system and information about your usage and activity on the
          Site. With your permission in the application, we may access your
          device’s photo library.
        </p>
        <p>
          <u>Cookies</u> - We automatically collect certain information through
          the use of "cookies". Cookies are small data files that are stored on
          your device by a Service. Among other things, the use of cookies helps
          us to improve the Service and your experience of the Service. We use
          cookies to see which areas and features of the Service are most
          popular, to count the number of devices accessing the Site, to
          personalize your experience and to remember your preferences. If your
          browser or device is set not to accept cookies or if you reject a
          cookie, you may not be able to access certain features or services of
          the Service.
        </p>

        <h3>Storage of Information & Transfer of Data</h3>
        <p>
          Most of the information we collect is retained by us on our servers.
          If you are using the PicCollage Service from a country other than the
          country in which our Web servers are located (United States), the
          various communications will necessarily result in the transfer of
          information across international boundaries.
        </p>

        <h2>Data Privacy</h2>
        <ol>
          <li>
            The information we collect from you includes the country you set
            your device to, the language you use on your device, the type and
            version of the operating system of your device, and your device
            model. If you make any in-app purchases, we will also collect the
            product IDs of the items you purchase.
          </li>

          <li>
            We collect this information from you to improve our app’s overall
            performance and the service we provide. We analyze this information
            with tools provided by third party companies. These companies
            include:
            <ol>
              <li>
                <a
                  href="https://developer.yahoo.com/flurry/legal-privacy/terms-service/flurry-analytics-terms-service.html"
                  target="_blank"
                >
                  Flurry
                </a>
                ,{' '}
                <a href="https://firebase.google.com/terms/" target="_blank">
                  Firebase
                </a>
                ,{' '}
                <a
                  href="https://www.facebook.com/legal/terms/update?ref=old_policy"
                  target="_blank"
                >
                  Facebook
                </a>
                ,{' '}
                <a
                  href="https://www.google.com/analytics/terms/us.html"
                  target="_blank"
                >
                  Google
                </a>
                ,{' '}
                <a
                  href="https://help.amplitude.com/hc/en-us/articles/206533238-Data-Security-Privacy"
                  target="_blank"
                >
                  Amplitude
                </a>
                ,{' '}
                <a href="https://fabric.io/terms" target="_blank">
                  Fabric
                </a>
              </li>

              <li>
                All these companies are GDPR-compliant and CCPA-compliant. You
                can learn more about the information they collect and analyze to
                ensure that PicCollage provides optimum user experience by
                clicking on the links above.
              </li>
            </ol>
          </li>

          <li>
            If you connect PicCollage to other social media platforms such as
            Facebook or Twitter, we will collect further information from you.
            <ol>
              <li>
                Facebook Authentication: authentication data as specified in the{' '}
                <a
                  href="https://www.facebook.com/about/privacy/update"
                  target="_blank"
                >
                  Facebook
                </a>{' '}
                privacy policy which include username, email, locale, and
                identifier.
              </li>

              <li>
                Twitter Authentication: authentication data as specified in the{' '}
                <a href="https://twitter.com/en/privacy#update" target="_blank">
                  Twitter
                </a>{' '}
                privacy policy which include your name, username, URL to your
                profile image and identifier.
              </li>
            </ol>
          </li>

          <li>
            We also provide you with personalized ads to show you content that’s
            tailored to you. The ads are provided by the following companies:
            <ol>
              <li>
                <a
                  href="https://www.inmobi.com/terms-of-service/"
                  target="_blank"
                >
                  InMobi
                </a>
                ,{' '}
                <a
                  href="https://www.ogury.com/terms-and-conditions/"
                  target="_blank"
                >
                  Ogury
                </a>
                ,{' '}
                <a href="https://vungle.com/privacy/" target="_blank">
                  Vungle
                </a>
                ,{' '}
                <a
                  href="https://www.facebook.com/business/gdpr"
                  target="_blank"
                >
                  Facebook
                </a>
                ,{' '}
                <a
                  href="https://www.receptiv.com/ad-services-privacy-policy"
                  target="_blank"
                >
                  Receptiv
                </a>
                ,{' '}
                <a
                  href="https://developers.ironsrc.com/ironsource-mobile/air/publisher-terms-conditions/"
                  target="_blank"
                >
                  IronSource
                </a>
                ,{' '}
                <a href="https://loopme.com/privacy/" target="_blank">
                  LoopMe
                </a>
                ,{' '}
                <a
                  href="https://www.smaato.com/resources/gdpr/"
                  target="_blank"
                >
                  Smaato
                </a>
                ,{' '}
                <a href="https://policies.google.com/privacy" target="_blank">
                  Google
                </a>
              </li>

              <li>
                All these companies are GDPR-compliant and CCPA-compliant. Click
                on the company links above to learn more about the information
                they collect and how they process it to serve you personalized
                content.
              </li>
            </ol>
          </li>

          <li>
            To provide users with an efficient way to communicate with
            PicCollage, we use{' '}
            <a
              href="https://www.zendesk.com/company/customers-partners/privacy-and-data-protection/"
              target="_blank"
            >
              Zendesk
            </a>
            , a customer service platform, to receive your feedback and offer
            timely responses to any questions you have. Please note we will
            receive your IP address and email address when you try to contact us
            through Zendesk.
          </li>

          <li>
            Users can create and purchase customized phone cases in the
            PicCollage app through KITE, a print-on-demand software. You can
            head over to KITE’s{' '}
            <a href="https://www.kite.ly/terms-and-conditions/" target="_blank">
              website
            </a>{' '}
            to learn more about how they process your information and orders.
          </li>

          <li>
            Please note PicCollage will collect your username and email address
            when you consent to create an account. Also, your username and the
            information you choose to share will be available to other social
            network users only when you set your account privacy to Public.
          </li>
        </ol>

        <h2>How We Use Your Personal Information</h2>
        <p>
          We use personal information collected through the PicCollage Service
          with your permission:
        </p>
        <ol>
          <li>to communicate with you or third parties;</li>
          <li>to process your requests and transactions;</li>
          <li>to improve the PicCollage Service;</li>
          <li>to customize the services and/or products we provide to you;</li>
          <li>to assist with our product and service development;</li>
          <li>to perform marketing activities;</li>
          <li>to provide relevant advertising; and</li>
          <li>for other purposes related to our business.</li>
        </ol>

        <h2>With Whom We Share Your Personal Information</h2>
        <p>
          Personal information (and non-personal information) collected through
          the PicCollage Service may be shared with companies and organizations
          that perform services on our behalf (for example, companies that
          provide data management or other support services to us such as data
          storage and Web hosting services). We may share your personal
          information (and non-personal information) with third parties to serve
          you relevant advertising and market our products, but we won't sell
          your personal information.
        </p>

        <p>
          Also, we may disclose the personal information we collect through the
          PicCollage Service when we, in good faith, believe disclosure is
          appropriate to:
          <ol>
            <li>
              comply with applicable law (e.g., in response to a valid court
              order or subpoena);
            </li>
            <li>
              prevent or investigate a possible crime, such as fraud or identity
              theft;
            </li>
            <li>
              enforce the Terms of PicCollage Service or other agreements that
              govern your use of the PicCollage Service and/or our services;
            </li>
            <li>
              to protect the rights, property or safety of us, our users or
              others; or
            </li>
            <li>to protect your vital interests.</li>
          </ol>
          If we are going to release your information in response to legal
          process, our policy is to provide you with reasonable advance notice
          under the circumstances unless we are prohibited from doing so by law
          or court order (e.g., an order under 18 U.S.C. § 2705(b)). We may
          disclose your information without giving you prior notice if we
          believe it is necessary to prevent imminent and serious bodily harm to
          a person. Nothing in this privacy policy is intended to limit any
          legal objections or defenses you might have to efforts by third
          parties to compel disclosure of your information, including legal
          orders from the government.
        </p>

        <p>
          Your personal information and other data collected by us may be
          transferred to another company that has acquired our stock or assets,
          for example, as a result of a sale, merger, reorganization,
          dissolution or liquidation. If such a transfer occurs, the acquiring
          company's use of your personal information will still be subject to
          this Privacy Policy.
        </p>

        <h2>Modifying Your Information - Opt-Out / Opt-In</h2>
        <p>
          We respect your right to make choices about the ways we collect, use
          and disclose your personal information. You may update or modify your
          information or change your privacy preferences at any time by emailing
          us at support@cardinalblue.com or, in some instances, via the
          PicCollage Service. You may opt out of receiving promotional
          communications from us, including, but not limited to, promotional
          emails, by emailing us at{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>
          . Transactional service communications (such as messages about your
          registration) are not subject to the foregoing opt-out procedures.
          Requests to disable your account or reset your password should be sent
          to{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>
          for faster processing.
        </p>

        <h2>SECURITY OF YOUR PERSONAL INFORMATION</h2>
        <p>
          We take reasonable security measures to protect your personal
          information (including preventing the loss, misuse, unauthorized
          access, disclosure, alteration and destruction of your personal
          information). However, we cannot guarantee that unauthorized third
          parties will not be able to defeat our security measures. If you use a
          password on the PicCollage Service, you are responsible for keeping it
          confidential. Do not share it with any other person. If you believe
          your password has been misused or your account compromised, please
          advise us immediately.
        </p>

        <h2>A NOTE TO CALIFORNIA RESIDENTS</h2>
        <p>
          If you are a California resident please see our Terms of Service to
          learn about your rights and access to data under CCPA.
        </p>
        <p>
          Additionally, California Civil Code Section 1798.83 permits you to
          request information regarding the disclosure of your personal
          information (if any) by PicCollage to third parties for the third
          parties' direct marketing purposes. To make this request, please email
          support@cardinalblue.com or contact us using this address: Cardinal
          Blue Software, P.O. Box 390677, Mountain View, CA 94039.
        </p>
        <p>
          If you are a California resident under the age of 18, and a registered
          user of any site where this policy is posted, California Business and
          Professions Code Section 22581 permits you to request and obtain
          removal of content or information you have publicly posted. To make
          such a request, please send an email with a detailed description of
          the specific content or information to{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>
          . Please be aware that such a request does not ensure complete or
          comprehensive removal of the content or information you have posted
          and that there may be circumstances in which the law does not require
          or allow removal even if requested.
        </p>

        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
        <p>
          We may change this Privacy Policy from time to time. If we make any
          changes to this Privacy Policy that we think materially alter your
          rights, then we will post the latest policy to this site and change
          the "Last Updated" date above. We encourage you to review this Privacy
          Policy whenever you visit the PicCollage Service
          <span>[LM8]</span>
          to understand how your personal information is used.
        </p>

        <h2>QUESTIONS</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{' '}
          <a href="mailto:support@cardinalblue.com">support@cardinalblue.com</a>
        </p>
        <p>Copyright 2020. All rights reserved.</p>
      </main>
    </App>
  )
}

export default Privacy
